.ant-modal-close.modal-app-required {
     color: #8a8062 !important;
}
.ant-modal.modal-app-required {
    padding:4%;   
}
.modal-app-required  .ant-modal-header{
    padding:21px 23px 21px 23px;
    border-radius: 4px 4px 0 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 0px  #e8e8e8 !important; 
}
.modal-app-required .ant-modal-close-x {
    width: 35px !important;
    height: 23px !important;
    line-height: 30px !important;
    font-size: 158% !important;
    color: #8a8062;
}
.modal-app-required .ant-btn {
    border-color: #8a8062 !important;
    color: #8a8062 !important;
}
.modal-app-required .ant-btn:hover, .ant-btn:focus, .ant-btn.active {
    color: #8a8062 !important;
}
.modal-app-required .ant-modal-body {
    padding: 1px;
}
.logoOuterDiv{
    display: flex;
}
.logoInnerDiv{
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 3% 0% 3% 0%;
}
.app-required-description{
    font-size: medium;
    font-family: 'Source Sans Pro', sans-serif !important;
    line-height: 1.3;
    padding-right: 7%;
    white-space: pre-line;
}
.app-required-ok-button{
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif !important;
    height: 38px !important;
}
.instaxLogoImage{
    height: 60pt; 
}
.app-required-close-btn {
    width: 32pt;
    height: 32pt;
    top: 0%;
    right: 0%;
    position: absolute;
    align-items: right;
}
