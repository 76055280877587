.title-txt {
    margin-top: 0.1rem;
  }
  h4.title-txt{
    /* font-size: 15pt; */
    font-size: 125%;
    padding: 0% 10%;
    margin-bottom: 0.2em !important;
  }
  h4.subtitle-txt{
    /* font-size: 12pt; */
    font-size: 100%;
    padding: 0% 10%;
  }
  .imgInfoIcon{
  width: 15%;
  }
  .downloadStatusDiv {
    background: rgba(0, 0, 0, 0.76);
    width: 100%;
    height: 100%;
    z-index: 100;
    position: fixed;
  }
  .dismissBtnDiv {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); 
  }

  @media  (orientation: landscape) {
    .downloadStatusDiv {
      background: rgba(0, 0, 0, 0.76);
      width: 100%;
      height: 100%;
      z-index: 100;
      position: fixed;
    }
    .dismissBtnDiv {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); 
      top: 75% !important;
    }
  }
  /*ipad specific */
  @supports (-webkit-overflow-scrolling: touch){  
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  h4.title-txt{
    font-size: 19pt;
  }
  h4.subtitle-txt{
    font-size: 16pt;
  }  
  button.ant-btn.downloadBtn {
  font-size: 15pt !important;
  }
  button.ant-btn.dismissBtn {
    font-size: 15pt !important;
  }
  .spanExpiryDate{
  font-size: 16pt;
  }
}
}