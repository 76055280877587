.regularBtn {
  /* grey-greenish color */
  border-color: #857e6b;
  border-radius: 12px;
  background-color: white;
  color: #857e6b;
  /* padding: 0.6rem 3.55rem; */
  padding: 11.7% 75.55%;
  height: auto;
}
.is-white {
  color: #fff;
}
.center-content {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 100%;  
    max-width: 500px;  
    line-height: 1.1;
}

@media  (orientation: landscape) {
  .center-content {
      margin: 0;
      position: absolute;
      left: 50%;
      -ms-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
      width: 100%;  
      max-width: 500px;  
      line-height: 1.1;
      top: 40% !important;
  }
}