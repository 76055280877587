body,html{
    height: 100%;
    width:100%;
    background-color:#f6f6f6!important ;
} 
.mainDiv{
    height: 100%;
    width:100%; 
}
.mainContainer
{
    display: flex;
    flex-direction: column; 
    width: 20%; /*5% decremented*/
    height: 71%;
    margin: 5% 0% 2% 38%;
    padding-top: 8%;    
    background-color: #f6f6f6; 
    
}
.logoImage{
    height: 48px;
}
.logoImageDivision{
    padding: 6px;
    flex: 1; 
    justify-content: center;
    display: flex
}
.picprop{
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 20% auto;
    }
.imageProperty {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 99;
    margin-left: -15.5%;   
    image-orientation: none; 
    /*it used for not roating image using exif info from chrome 81*/
 }

 .baseImageSamsung{
    width: 100%;
    transform: rotate(-90deg);
    bottom: -53%;
    right: -2%;
    position: absolute;
 }
      
.imagePropertyIos{
    width: 100%;
    position: relative;
    padding: 18% 12% 18% 12%;
}
.picpropIos{
    position: relative;
    margin-top:-15% ;
}
.baseImage {
    transform: rotate(-90deg);
    position: absolute;
    z-index: 9;
    filter:  drop-shadow(0 3px 10px rgba(0, 0, 0, .16));
    width: 97%;
}
.baseImageIos{
    width: 100%;
    position: absolute;
    filter: drop-shadow(0 3px 10px rgba(0, 0, 0, .16));   
}
.class_webplay_btnicon{ 
    position: absolute;
    width: 25%;
    left: 29.5%;
    bottom: 0;
    right: 0;
    top: 33%;
    z-index: 99;
}
.class_webplay_btnicon_ios{
    position: absolute;
    width: 25%;
    left: 36.7%;
    top: 41%; 
    z-index: 99;
}

.spinLoadClass{
    min-height: 700px 
}

.imageDescription{
    font-size: 15px
}

.spanExpiryDate{
    color: #7e7e7e;
    font-size: 108%;   
    white-space: nowrap;
    overflow: hidden;
}

.downloadBtnDiv{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrap-download-install-btn{
    display: inherit !important;
}

button.ant-btn.downloadBtn[disabled] {
    /* background: lightgray!important; */
    color: lightgray!important;
    border-color: lightgray!important;
}

.ant-modal.modal-password-prompt {
    padding:4%;   
    top: 10%;
    margin: auto !important;
}

.warn-download-not-support{
    font-family: 'Source Sans Pro', sans-serif !important;
    padding-right: 8%;
}
.ant-alert-with-description .ant-alert-icon {
    font-size: 15px !important;
}
.ant-alert-with-description .ant-alert-close-icon {
    top:8px !important
}
.ant-alert-with-description {
    padding: 10px 15px 15px 48px !important;
}

.download-install-btn-width{
    width: 71pt;
}
  /* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .mainDiv{
        height: 100%;
        width:100%; 
    }
    .mainContainer
    {
        display: flex;
        flex-direction: column;
        width: 20%;
        height: 77%;
        margin: 3% auto;
        padding-top: 0px;        
    }  
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
    .mainContainer
    {
        display: flex;
        flex-direction: column;
        width: 45%;
        height: 77%;  
        margin: 8% auto;
        padding: 0px;
    }    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  @media (min-width: 768px) and (max-width: 1024px) {
    
    .mainContainer
    {
        display: flex;
        flex-direction: column;
        width: 81%;
        height: 100%;
        margin: 2% auto;
    }
    .mainDiv{
        margin: 0 3%;
        width: 94%;
    }
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .mainContainer
    {      
        display: flex;
        flex-direction: column;
        width: 81%;
        height: 100%;
        margin: 2% auto;        
    }
    .spinLoadClass{
        min-height:650px 
    }
    .mainDiv{
        margin: 0;
        width: 100%;
    }
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 451px) and (max-width: 767px) {
    .mainDiv{
        margin: 0;
        width: 100%;
    }
    .class_webplay_btnicon_ios{
        position: absolute;
        width: 25%;
        left: 36.7%;
        top: 41%; 
        z-index: 99;
    }
    .mainContainer
    {
        display: flex;
        flex-direction: column;
        width: 71%;
        height: 100%;
        margin: 2% auto;
    }
    .spinLoadClass{
        min-height:500px 
    }   
   .logoImage{
        height: 45px;
        max-width: 100%;
    }
    .imageProperty{
        width: 100%;
         height: 100%
    }
  }
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 450px) {
    .class_webplay_btnicon_ios{
        position: absolute;
        width: 25%;
        left: 36.7%;
        top: 41%; 
        z-index: 99;
    }
    .mainContainer
    {
        display: flex;
        flex-direction: column;
        width: 74%;
        height: 100%;
        margin: auto;
    }
    .spinLoadClass{
        min-height:400px 
    }  
    .logoImage{height: 42px;
    }
    .imageDescription{
        font-size:14px
    }
    body{
        overflow-x: hidden;
     }
  }

/* for 18:9 devices */
  @media (max-width: 450px) and (min-height: 670px) and (min-width: 320px){
    .mainContainer {
        display: flex;
        flex-direction: column;
        width: 86%;
        height: 100%;
        margin: auto;
    }
    .logoImageDivision{
        padding-top: 2px;
        padding-bottom: 4.5%;
    }
}

  @supports (-webkit-overflow-scrolling: touch){
    @media (min-width: 320px) and (max-width: 450px) {
        /* CSS specific to iOS devices */ 
        .mainContainer
        {
            display: flex;
            flex-direction: column;
            width: 70%;
            padding-top: 5%;
        } 
    }

    /*ipad specific style*/
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
        .mainContainer
        {
            display: flex;
            flex-direction: column;
            width: 64%;
            padding-top: 5%;
        } 
        .download-install-btn-width {         
            width: 90pt !important;
            font-family: 'Source Sans Pro', sans-serif !important;
        }
    }

    /*for device with 18:9*/
    @media (max-width: 450px) and (min-height: 700px) and (min-width: 350px){
        .mainContainer {
            display: flex;
            flex-direction: column;
            width: 82%;
            height: 100%;
            margin: auto;
            padding-top: 4%;
        }
        .logoImageDivision{
            padding-top: 2px;
            padding-bottom: 6%;
        }
    }
}
 /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = UPto 319px
  */
  @media  (max-width: 319px) {
    .mainContainer
    {
        display: flex;
        flex-direction: column;
        width: 81%;
        height: 96%;
        margin: auto;
    }
    .spinLoadClass{
        min-height:350px 
    }
    .imageDescription{
        font-size:10px
    }    
    .logoImage{
        height: 40px;
    } 
    .spanExpiryDate{
    font-size: 95%;   
    }  
  }
  
  /* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    .baseImage {       
    margin-top:-75%;
    margin-left: -7%;
    }
 }