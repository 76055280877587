body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Source Sans Pro', sans-serif !important;
}
/* body { -webkit-touch-callout: none !important; } */
/* img,svg { -webkit-user-select: none !important; } */
.baseImageIos,.logoImage,.class_webplay_btnicon_ios {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
}